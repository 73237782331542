export default {
	state: {
		usuario: {}
	},
	getters: {
		"usuario": state => state.usuario
	},
	mutations: {
		setUsuario(state, payload) {
			state.usuario = payload
		}
	},
	actions: {
		atualizarEstadoUsuario({ commit }, payload) {
			commit('setUsuario', payload)
		}
	}
};