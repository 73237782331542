<template>
      <Nav v-if="showNavAndSidebar"></Nav>
      <SidBar v-if="showNavAndSidebar"></SidBar>
    <router-view/>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Nav from '@/components/Nav.vue';
import SidBar from '@/components/Sidbar.vue';

export default {
  components: {
    Nav,
    SidBar,
  },
  setup() {
    const route = useRoute();
    const showNavAndSidebar = computed(() => route.name !== 'login' && route.name !== 'verification');
       
    return {
      showNavAndSidebar,
    };
  },
};
</script>