const axios = require('axios');



const auth = {
    async verificarToken(token) {
        try {
            const response = await axios.get('https://apienerge.apololab.net:5010/token', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.log('Erro ao obter verificarToken:', error.response.data.message);
           
            return error.response.data.message
             
        }
    }

};



export default auth;