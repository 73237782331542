<template>
  <div class="home">
    <div class="app-container">
      <!-- App header ends -->
      <!-- App body starts -->
      <div class="app-body">
        <!-- Container starts -->
        <div class="container-fluid">
          <!-- Row start -->
          <div class="row align-items-center mb-3">
            <div class="col-12 col-xl-4">
              <h2 class="mb-2">Importação Arquivos</h2>
              <h6 class="mb-2 fw-light text-dark small">
                DADOS - Faturas
              </h6>
            </div>

          </div>
          <!-- Row end -->

          <!-- Row start -->
          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-body" style="color: black;">
                  <h6 style="color: white;">Faturas Importadas</h6>
                  <DxDataGrid :data-source="dadosArray" key-expr="id" :allow-column-resizing="true"
                    :column-resizing-mode="'nextColumn'" :column-min-width="50" :column-auto-width="true"
                    :allow-column-reordering="true" :show-column-lines="true" :show-row-lines="true"
                    :show-borders="true" :row-alternation-enabled="true">

                    <!-- CONFIGURACAO PADRAO -->
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="10" />

                    <!-- COLUNAS -->

                    <DxColumn caption="Razao Social" data-field="razao_social" />
                    <DxColumn caption="CNPJ" data-field="cnpj" />
                    <DxColumn caption="Valor Ftura" data-field="valor_fatura" />
                    <DxColumn caption="Mês Referência" data-field="mes_referencia" />
                    <DxColumn caption="Vencimento" data-field="vencimento" />
                    <DxColumn caption="Distribuidora" data-field="distribuidora" />
                    <DxColumn caption="Ramo de Atividade" data-field="ramo_atividade" />
                    <DxColumn caption="Nº de Identificação" data-field="numero_instalacao" />
                    <DxColumn caption="Modalidade Tarifária Atual" data-field="modalidade_tarifaria_atual" />

                    <!-- SUB-GRID INTERNA -->
                    <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
                    <template #masterDetailTemplate="{ data }">
                      <div class="row">
                        <div class="col" style="max-width: 16rem;">
                          <div>
                            <p>Histórico de Consumo Ponta</p>
                          </div>
                          <DxDataGrid :data-source="data.data.historico" :show-borders="true" :column-auto-width="true">
                            <DxColumn data-field="mes" caption="Mês" />
                            <DxColumn data-field="valor" caption="Valor"
                              :format="{ type: 'fixedPoint', precision: 2 }" />

                          </DxDataGrid>
                        </div>
                        <div class="col" style="max-width: 16rem;">
                          <div>
                            <p>Histórico de Consumo Fora Ponta</p>
                          </div>
                          <DxDataGrid :data-source="data.data.historicoFora" :show-borders="true" :column-auto-width="true">
                            <DxColumn data-field="mes" caption="Mês" />
                            <DxColumn data-field="valor" caption="Valor"
                              :format="{ type: 'fixedPoint', precision: 2 }" />

                          </DxDataGrid>
                        </div>
                      </div>
                           
                    </template>
                    
                    <!-- CONFIGURACAO PADRAO -->
                    <DxColumnChooser :enabled="true" />
                    <DxFilterRow :visible="true" />
                    <DxHeaderFilter :visible="true" />
                    <DxGroupPanel :visible="true" />
                    <DxPager :visible="true" :display-mode="'full'" :show-page-size-selector="true" :show-info="true"
                      :show-navigation-buttons="true" :allowed-page-sizes="'true'" />
                    <DxExport :enabled="true" :allow-export-selected-data="true" />

                  </DxDataGrid>
                </div>
              </div>

            </div>
          </div>

        </div>
        <!-- Container ends -->
      </div>
      <!-- App body ends -->
      <!-- App footer start -->
      <div class="app-footer">
        <span>© Apolo Lab 2024</span>
      </div>
      <!-- App footer end -->

    </div>
  </div>
</template>

<script>
import {  mapGetters } from "vuex";
import apiMethods from './services/homeService';
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPager,
  DxPaging,
  DxExport,
  DxColumnChooser,
  DxMasterDetail
} from "devextreme-vue/data-grid";

export default {
  name: 'HomeView',
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPager,
    DxPaging,
    DxExport,
    DxColumnChooser,
    DxMasterDetail
  },
  computed: {
    ...mapGetters({
        usuario: "usuario"
    })
  },
  data() {
    return {
      dadosUsuario: {},
      dadosArray: []
    }
  },
  created() {
  
    this.dadosUsuario = this.usuario;
   
    console.log("log usuario home", this.dadosUsuario);
    

    this.dadosFatura();
  },
  methods: {
    async dadosFatura() {
      try {
        const fatura = await apiMethods.obterFaturas();

        this.dadosArray = fatura.map(h => {
          let historicoPonta = [];
          if (h.historico_consumo_ponta) {
            historicoPonta = JSON.parse(h.historico_consumo_ponta) || [];
          }

          let historicoPontaFora = [];
          if (h.historico_consumo_fora_ponta) {
            historicoPontaFora = JSON.parse(h.historico_consumo_fora_ponta) || [];
          }

          let historico = [];
          if (h.historico) {
            historico = JSON.parse(h.historico) || [];
          }

          h.historico = this.padronizarHistorico(historicoPonta.length ? historicoPonta : historico);
          h.historicoFora = this.padronizarHistoricoFora(historicoPontaFora.length ? historicoPontaFora : historico);
          return h;
        });

        console.log("fatura tela", this.dadosArray);
      } catch (error) {
        console.error("Erro ao obter faturas:", error);
      }
    },
    padronizarHistorico(historico) {
      return historico.map(entry => {
        return {
          mes: entry["mes"] || entry["Mês/Ano"] || entry["Mês"],
          valor: entry["consumo"] || entry["Demanda(kW)"] || entry["Demanda"] || entry["Consumo"]
        };
      });
    },
    padronizarHistoricoFora(historico) {
      return historico.map(entry => {
        return {
          mes: entry["mes"] || entry["Mês/Ano"] || entry["Mês"],
          valor: entry["consumo"] || entry["Demanda(kW)"] || entry["Demanda"] || entry["Consumo"]
        };
      });
    }
  }
}
</script>
