const apiClient = require('../../validador/apiClient');
const store = require('../../store/index').default;
const apiMethods = {

  async usuarios() {
    try {
      const response = await apiClient.get('/usuarios');
      return response.data;
    } catch (error) {
      console.error('Erro ao obter usuarios:', error);
      throw error;
    }
  },

  async login(email, password) {
    const user = { email, password };
    try {
      const response = await apiClient.post('/login', user);
      if (response.data.token) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', response.data.nome);
        store.dispatch('atualizarEstadoUsuario', response.data);
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`; // Atualiza o token após o login
        return response.data.status;
      } else {
        return response.data.status;
      }
    } catch (error) {
      console.error('Erro ao login:', error.response.data);
      throw error;
    }
  },

  async obterFaturas() {
    try {
      const response = await apiClient.get('/obterFaturas');
      return response.data;
    } catch (error) {
      console.error('Erro ao obter faturas:', error.response.data);
      throw error;
    }
  },

  async criaUsuarios(dados) {
    try {
      const response = await apiClient.post('/createUsers', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar usuário:', error);
      throw error;
    }
  },

  async validation(token) {
    try {
      const response = await apiClient.get(`/validation/${token}`);
      return response.data;
    } catch (error) {
      console.log('Erro ao validation:', error);
      throw error;
    }
  },

  async alteraSenha(dados) {
    try {
      const response = await apiClient.post('/alterasenha', dados);
      return response.data;
    } catch (error) {
      console.log('Erro ao alterar senha:', error);
      throw error;
    }
  },

  async editaUsuario(dados) {
    try {
      const response = await apiClient.post('/editarUsuario', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao editar usuário:', error);
      throw error;
    }
  },

  async deleteUsuario(dados) {
    try {
      const response = await apiClient.post('/deleteUsuario', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      throw error;
    }
  }

  // Adicione mais métodos conforme necessário
};

module.exports = apiMethods;
