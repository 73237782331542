<template>
     <nav id="sidebar" class="sidebar-wrapper">
                <!-- App brand starts -->
                <div class="app-brand px-3 py-2 d-flex align-items-center">
                    <router-link to="/">
                        <img src="assets/images/apolo_logo-semFundo.png" class="logo" alt="Apolo Lab" />
                    </router-link>
                </div>
     </nav>

</template>
  
  <script>
  export default {
    name: 'Nav',
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  