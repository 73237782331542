<template>
  <div v-if="isVisible" class="modal" @animationend="handleAnimationEnd">
    <div class="modal-content" :class="{ 'slide-out': isClosing }">
      <div class="modal-header">
        <h5 class="modal-title h4">Importe seu arquivo</h5>
        <button type="button" class="btn-close" @click="hide" aria-label="Close">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="card col-lg-8">
            <input type="file" @change="uploadDocument" />
            <div v-if="documentContent">
              <h3>Conteúdo do Documento:</h3>
              <div v-if="isImage">
                <img :src="documentContent" alt="Imagem" style="max-width: 100%;" />
              </div>
              <div v-else-if="isPdf">
                <iframe :src="documentContent" width="100%" height="1000px"></iframe>
              </div>
              <div v-else>
                <pre>{{ documentContent }}</pre>
              </div>
            </div>
          </div>
          <div class="card col-lg-4">
            <div v-if="transformedData">
              <h3>Conteúdo do Documento:</h3>
              <div>
                <div class="card-body">
                  <label class="form-label">Razão Social</label>
                  <input type="text" v-model="transformedData.razaoSocial" name="custoEletrica" class="form-control" :class="{ 'invalid': !transformedData.razaoSocial }" />
                  <label class="form-label">CNPJ</label>
                  <input type="text" v-model="transformedData.cnpj" :class="{ 'invalid': !transformedData.cnpj }"
                    name="custoEletrica" class="form-control" required />
                  <label class="form-label">Endereço</label>
                  <input type="text" v-model="transformedData.endereco"
                    :class="{ 'invalid': !transformedData.endereco }" name="custoEletrica" class="form-control"
                    required />
                  <label class="form-label">Mês de referência</label>
                  <input type="text" v-model="transformedData.mesReferencia"
                    :class="{ 'invalid': !transformedData.mesReferencia }" name="custoEletrica" class="form-control"
                    required />
                  <label class="form-label">Nº de Identificação</label>
                  <input type="text" v-model="transformedData.numeroInstalacao"
                    :class="{ 'invalid': !transformedData.numeroInstalacao }" name="custoEletrica"
                    class="form-control" />
                  <label class="form-label">Distribuidora</label>
                  <input type="text" v-model="transformedData.distribuidora"
                    :class="{ 'invalid': !transformedData.distribuidora }" name="custoEletrica" class="form-control" />
                  <label class="form-label">Ramo de Atividade</label>
                  <input type="text" v-model="transformedData.ramoAtividade"
                    :class="{ 'invalid': !transformedData.ramoAtividade }" name="custoEletrica" class="form-control" />
                  <label class="form-label">Modalidade Tarifária Atual</label>
                  <input type="text" v-model="transformedData.modalidadeTarifariaAtual"
                    :class="{ 'invalid': !transformedData.modalidadeTarifariaAtual }" name="custoEletrica"
                    class="form-control" />
                  <label class="form-label">Vencimento</label>
                  <input type="text" v-model="transformedData.vencimento"
                    :class="{ 'invalid': !transformedData.vencimento }" name="custoEletrica" class="form-control" />
                  <label class="form-label">Valor</label>
                  <input type="text" v-model="transformedData.valorFatura"
                    :class="{ 'invalid': !transformedData.valorFatura }" name="custoEletrica" class="form-control" />
                  <br>
                  <div class="card border-primary mb-6" style="background-color: orange; max-width: 25rem;">
                    <div class="card-header">Histórico de Consumo Ponta</div>
                    <div class="card-body text-primary">
                      <div v-for="(item, index) in transformedData.historico" :key="index" class="input-group mb-2">
                        <input class="form-control input-group-text" id="basic-addon3" style="min-width: 10rem; max-width: 10rem;"
                        v-model="item.mes">
                        </input>
                        <input type="text" class="form-control" id="basic-url" v-model="item.consumo"
                          :class="{ 'invalid': !item.consumo }" aria-describedby="basic-addon3" />
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="card border-primary mb-6" style="background-color: orange; max-width: 25rem;">
                    <div class="card-header">Histórico de Consumo Fora Ponta</div>
                    <div class="card-body text-primary">
                      <div v-for="(item, index) in transformedData.historicoFora" :key="index" class="input-group mb-2">
                        <input class="form-control input-group-text" id="basic-addon3" style="min-width: 10rem; max-width: 10rem;"
                        v-model="item.mes">
                        </input>
                        <input type="text" class="form-control" id="basic-url" v-model="item.consumo"
                          :class="{ 'invalid': !item.consumo }" aria-describedby="basic-addon3" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" @click="importar">Analisar</button>
        <button type="button" class="btn btn-warning" @click="hide">Fechar</button>
        <button type="button" class="btn btn-warning" @click="salva">Salvar</button>
      </div>
    </div>
    <Spinner :loading="isLoading" />
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import axios from 'axios';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'ModalPerfil',
  components: {
    Spinner,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      isVisible: false,
      isClosing: false,
      documentContent: null,
      isImage: false,
      isPdf: false,
      selectedFile: null,
      transformedData: [],
      isLoading: false,
    };
  },
  methods: {
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    show() {
      this.isClosing = false;
      this.isVisible = true;
    },
    hide() {
      this.isClosing = true;
    },
    handleAnimationEnd() {
      if (this.isClosing) {
        this.isVisible = false;
        this.isClosing = false;
      }
    },
    uploadDocument(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        const fileType = file.type;
        if (fileType.startsWith('image/')) {
          this.isImage = true;
          this.isPdf = false;
          this.documentContent = URL.createObjectURL(file);
        } else if (fileType === 'application/pdf') {
          this.isImage = false;
          this.isPdf = true;
          this.documentContent = URL.createObjectURL(file);
        } else {
          this.isImage = false;
          this.isPdf = false;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.documentContent = e.target.result;
          };
          reader.readAsText(file);
        }
      }
    },
    async importar() {
      this.isLoading = true;
      if (!this.selectedFile) {
        alert("Por favor, selecione um arquivo primeiro!");
        this.isLoading = false;
        return;
      }
      const fileType = this.selectedFile.type;
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      try {
        let res;
        if (fileType === 'application/pdf') {
          res = await axios.post('https://apienerge.apololab.net:5010/geminiFaturaPdf', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          res = await axios.post('https://apienerge.apololab.net:5010/geminiFatura', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
        const data = res.data;

        const dadosArray = []

        dadosArray.push({
          razaoSocial: data["Razão Social"],
          cnpj: data["CNPJ"],
          valorFatura: data["VALOR"],
          numeroInstalacao: data["Nº de Identificação"],
          endereco: data["Endereço"],
          distribuidora: data["Distribuidora"],
          mesReferencia: data["Mês de referência"],
          vencimento: data["Vencimento"],
          classeConsumo: data["Classe de consumo"],
          modalidadeTarifariaAtual: data["Modalidade Tarifária Atual"],
          estruturaTarifariaAtual: data["Estrutura Tarifária Atual"],
          demandaContratadaPonta: data["Demanda Contratada Ponta"],
          demandaContratadaForaPonta: data["Demanda Contratada Fora Ponta"],
          ramoAtividade: data["Ramo de Atividade"],
          historicoConsumoPonta: data["Histórico de Consumo Ponta"] || [],
          historicoConsumoForaPonta: data["Histórico de Consumo Fora Ponta"] || []
        });

        let array = []
        array = dadosArray.map(h => {
          let historicoPonta = [];
          if (h.historicoConsumoPonta) {
            historicoPonta = h.historicoConsumoPonta || [];
          }

          let historicoPontaFora = [];
          if (h.historicoConsumoForaPonta) {
            historicoPontaFora = h.historicoConsumoForaPonta || [];
          }

          let historico = [];
          if (h.historico) {
            historico = h.historico || [];
          }

          h.historico = this.padronizarHistorico(historicoPonta.length ? historicoPonta : historico);
          h.historicoFora = this.padronizarHistoricoFora(historicoPontaFora.length ? historicoPontaFora : historico)
          return h;
        });

        this.transformedData = array[0]

      } catch (error) {
        console.error("Erro durante o upload:", error);
      } finally {
        this.isLoading = false;
      }
    },
    padronizarHistorico(historico) {
      return historico.map(entry => {
        return {
          mes: entry["mes"] || entry["Mês/Ano"] || entry["Mês"] || entry["Mes"],
          consumo: entry["consumo"] || entry["Demanda(kW)"] || entry["Demanda"] || entry["Consumo"] || entry["Consumo (kWh)"]
        };
      });
    },
    padronizarHistoricoFora(historico) {
      return historico.map(entry => {
        return {
          mes: entry["mes"] || entry["Mês/Ano"] || entry["Mês"] || entry["Mes"],
          consumo: entry["consumo"] || entry["Demanda(kW)"] || entry["Demanda"] || entry["Consumo"] || entry["Consumo (kWh)"]
        };
      });
    },
    validaCampos() {
      let valid = true;
      const requiredFields = ['cnpj', 'endereco', 'mesReferencia', 'numeroInstalacao', 'distribuidora', 'ramoAtividade', 'modalidadeTarifariaAtual', 'vencimento', 'valorFatura'];

      for (const field of requiredFields) {
        if (!this.transformedData[field]) {
          valid = false;
          this.$set(this.transformedData, field, this.transformedData[field] || '');
        }
      }

      this.transformedData.historico.forEach((item) => {
        if (!item.consumo) {
          valid = false;
          this.$set(item, 'consumo', item.consumo || '');
        }
      });

      return valid;
    },
    async salva() {
      this.isLoading = true
      if (this.transformedData.razaoSocial && this.transformedData.cnpj) {
        
      
      const dataSalve = {
        razaoSocial: this.transformedData.razaoSocial,
        cnpj: this.transformedData.cnpj,
        valorFatura: this.transformedData.valorFatura,
        numeroInstalacao: this.transformedData.numeroInstalacao,
        endereco: this.transformedData.endereco,
        distribuidora: this.transformedData.distribuidora,
        mesReferencia: this.transformedData.mesReferencia,
        vencimento: this.transformedData.vencimento,
        classeConsumo: this.transformedData.classeConsumo,
        modalidadeTarifariaAtual: this.transformedData.modalidadeTarifariaAtual,
        estruturaTarifariaAtual: this.transformedData.estruturaTarifariaAtual,
        demandaContratadaPonta: JSON.stringify(this.transformedData.demandaContratadaPonta),
        demandaContratadaForaPonta: JSON.stringify(this.transformedData.demandaContratadaForaPonta),
        ramoAtividade: this.transformedData.ramoAtividade,
        historicoConsumoPonta: this.transformedData.historicoConsumoPonta,
        historicoConsumoForaPonta: this.transformedData.historicoConsumoForaPonta,

      };

      try {
        const res = await axios.post('https://apienerge.apololab.net:5010/salvaFatura', dataSalve);
        console.log("Resposta do servidor:", res.data);
        if (res.data) {
          this.isLoading = false
        }

        if (res.data.mensage === 'success') {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Dados Salvos Com Sucesso !!",
          }).then(() => {
            location.reload(); // Atualiza a página após clicar "OK"
          });

        } else {
          this.isLoading = false
          Swal.fire({
            icon: "error",
            title: "Ops! Erro ao Salvar",
            text: "Tente Novamente Mais Tarde !!",
          });
          return;
        }

      } catch (error) {
        console.log("Houve um erro ao enviar o arquivo:", error);
      }
    } else {
      this.isLoading = false
          Swal.fire({
            icon: "error",
            title: "Ops! Erro ao Salvar",
            text: "Importe um arquivo valido  !!",
          });
          return;
        }
    }
    }
  }

</script>
<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgb(65, 64, 64);
  box-sizing: border-box;
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-out {
  animation: slide-out 0.5s forwards;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
}

.btn-close:hover {
  color: #ff0000;
}

.modal-header,
.modal-body {
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.modal-footer button {
  margin-left: 10px;
}

.invalid {
  border: 2px solid red;
}
</style>
