import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import HomeView from '../views/HomeView.vue'
import auth from '../validador/TokenValido'; // Substitua pelo caminho correto para o arquivo auth.js

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/gestaoUsuarios',
    name: 'GestaoUsuarios',
    component: () => import(/* webpackChunkName: "gestaoUsuarios" */ '../views/GestaoUsuarios.vue')
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import(/* webpackChunkName: "gestaoUsuarios" */ '../views/Verification.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});



router.beforeEach(async (to, from, next) => {
  try {
    // Supondo que você tenha uma ação que carrega o usuário
    await store.dispatch('usuario'); // Ajuste conforme necessário

    const token =   sessionStorage.getItem('token');
    console.log("token", token);

    let autH = await auth.verificarToken(token);
    if (autH === 'jwt expired' && to.name !== 'verification') {
      autH = false;
      // sessionStorage.removeItem('token');
    }
    if (autH === 'jwt malformed' && to.name !== 'verification') {
      autH = false;
      // sessionStorage.removeItem('token');
    }
    
    const isAuthenticated = token && autH;

    if (to.name !== 'login' && !isAuthenticated && to.name !== 'verification') {
      next('/login'); // Redireciona para a página de login se o usuário não estiver autenticado e não estiver na página de login
    } else {
      next(); // Continua a navegação normalmente
    }
  } catch (error) {
    console.error('Error during navigation guard:', error);
    next('/login'); // Redireciona para a página de login em caso de erro
  }
});


export default router;
